import React from 'react'
import { MDBCol, MDBCard, MDBCardBody, MDBCardText, } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"
import Link from 'gatsby-link'

class Card extends React.Component {
  render() {
    const colHeight = this.props.height != null ? this.props.height : "auto"
    return (
      <MDBCol
        lg={this.props.collg}
        md={this.props.colmd}
        className="pb-card d-flex"
      >
        <MDBCard className="flex-fill">
        <Link to={this.props.link}>
          <GatsbyImage
            image={this.props.image}
            alt={this.props.alt}
            className="rounded-top drop-shadow"
          />
          </Link>
          <MDBCardBody>
            <div style={{maxHeight: colHeight, minHeight: colHeight}} >
              <h3 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-medium mt-2">
                {this.props.title}
              </h3>
              {this.props.subtitle && (
                <div className="font-w-400 text-medium text-lg-large mt-2" dangerouslySetInnerHTML={{ __html: this.props.subtitle, }} />
              )}
            </div>
            <hr />
            <MDBCardText className="font-w-400 text-card-small mt-2 mb-2">
              {(this.props.link.substring(0, 4) != 'http' && this.props.link.substring(0, 8) != '/static/') ? (
                <Link to={this.props.link} className="effect">
                  {this.props.description}
                </Link>
              ) : (
                <a href={this.props.link} className="effect" target="_blank" rel="noopener">
                  {this.props.description}
                </a>
              )}
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    )
  }
}

export default Card