import React from 'react'
import Link from 'gatsby-link'
import Showdown from 'showdown'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import Card from '../components/card'
import Cta from '../components/cta'
import CarouselQuote from '../components/carouselQuote'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { MDBRow, MDBCol, MDBContainer, MDBAnimation } from 'mdbreact'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import { faWindows } from '@fortawesome/free-brands-svg-icons'

library.add(fat)

const ProductTemplate = ({ data }) => {
  const post = data.markdownRemark
  const heroImage = data.heroImage
  const converter = new Showdown.Converter()
  return (
    <div id="sub-page">
      <Layout>
        <Hero
          class="intro-65"
          image={heroImage?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.description}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}     
          type="root"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol lg={ post.frontmatter.headline && post.frontmatter.headline.length >= 1 ? '8' : '12' } >
                <div
                  className="font-w-400 text-xs-extra-large divlink text-opening-para"
                  dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.openingpara), }}
                />
                <div 
                  className="richtext divlink" 
                  dangerouslySetInnerHTML={{ __html: post.html }} />
              </MDBCol>

              {post.frontmatter.headline &&
                post.frontmatter.headline.length >= 1 && (
                  <MDBCol lg="4" md="12" className="pl-lg-5">
                    {post.frontmatter.headline.map((headlines, index) => (
                      <div className="headline-tile mt-lg-3 item-headline" key={index} >
                        <div className="headline-icon">
                          <FontAwesomeIcon icon={faWindows} size="2x" className="fa-fw" />
                        </div>
                        <div className="headline-content">
                          <h3 className="font-alt font-w-700 letter-spacing-1 text-xs-medium text-medium">
                            {headlines.title}
                          </h3>
                          <div className="headline-action font-alt letter-spacing-1 text-xs-medium text-small">
                            {headlines.resource ? (
                              <a href={headlines.resource.publicURL} className="effect-noline" target="_blank" rel="noopener" >
                                {headlines.linktext}
                                <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                              </a>
                            ) : headlines.link.charAt(0) != '/' ? (
                              <a href={headlines.link} className="effect-noline" target="_blank" rel="noopener" >
                                {headlines.linktext}
                                <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                              </a>
                            ) : (
                              <Link to={headlines.link} className="effect-noline" >
                                {headlines.linktext}
                                <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </MDBCol>
                )}
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.section.map((sections, index) => (
          <section className="bg-light-blue-prods" key={index}>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="4" className="pr-5">
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                    {sections.title}
                  </h2>
                  <p className="text-medium text-gray-dark mt-3">
                    {sections.description}
                  </p>
                </MDBCol>

                <MDBCol md="8">
                  <MDBRow>
                    {sections.feature.map((features, index) => (
                      <MDBCol md="6" className="pb-5 item-prods" key={index}>
                        <div className="feature-icon">
                          <FontAwesomeIcon
                            icon={['fat', features.icon]}
                            size="3x"
                          />
                        </div>
                        <p className="text-medium text-blue-dark font-w-700">
                          {features.title}
                        </p>
                        <p className="text-medium text-gray-dark">
                          {features.description}
                        </p>
                        {features.link !== null && (
                          <p className="text-small">
                            <Link to={features.link} className="effect">
                              {features.linktext}
                            </Link>
                          </p>
                        )}
                      </MDBCol>
                    ))}
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>
        ))}

      {post.frontmatter.quote && (
        <CarouselQuote data={post.frontmatter.quote} />
      )}

        {post.frontmatter.helpfulresources && (
          <section className="bg-white-grey-gradient">
            <MDBAnimation reveal type="fadeIn" duration="1587ms">
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left pb-3">
                  {post.frontmatter.helpfulresources.title}
                  <span id="helpful-resources" className="anchor">
                    &nbsp;
                  </span>
                </h2>

                {post.frontmatter.helpfulresources.subtitle && (
                  <p className="text-medium">
                    {post.frontmatter.helpfulresources.subtitle}
                  </p>
                )}

                {post.frontmatter.helpfulresources.helpfulres && (
                  <MDBRow className="pt-5">
                    {post.frontmatter.helpfulresources.helpfulres.map(
                      (helpfulres, index) => (
                        <Card
                          key={index}
                          collg="4"
                          colmd="6"
                          height="4rem"
                          title={helpfulres.title}
                          description={helpfulres.description}
                          image={ helpfulres.image.childImageSharp.gatsbyImageData }
                          alt={helpfulres.alttext}
                          placement={helpfulres.placement}
                          link={ helpfulres.link ? helpfulres.link : helpfulres.document.publicURL }
                          titleclass="title-small"
                          descriptionClass="text-card-small py-2"
                        />
                      )
                    )}
                  </MDBRow>
                )}
              </MDBContainer>
            </MDBAnimation>
          </section>
        )}

        {post.frontmatter.cta && (
          <Cta
            ctatitle={post.frontmatter.cta.title}
            ctadescription={post.frontmatter.cta.description}
            ctalink={post.frontmatter.cta.link}
            ctalinktext={post.frontmatter.cta.linktext}
          />
        )}
      </Layout>
    </div>
  )
}
export default ProductTemplate

export function Head({ data, location }) {
  const post = data.markdownRemark
  const heroImage = data.heroImage
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.title}
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "products" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description
        }
        template
        title
        subtitle
        description
        ctatext
        ctaslug     
        openingpara
        suite
        strapline
        author        
        headline {
          title
          icon
          linktext
          link
        }
        section {
          title
          description
          feature {
            title
            icon
            description
            linktext
            link
          }
        }
        quote {
          strapline
          author
        }        
        helpfulresources {
          title
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            document {
              publicURL
            }
          }
        }
        cta {
          title
          description
          linktext
          link
        }
      }
    }
    heroImage: file(name: { eq: "product-banner" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`
